import { useTheme } from "@emotion/react";
import { Button, styled } from "@mui/material";
import React from "react";
import { GrPlay } from "react-icons/gr";

export const PlayButton = ({ children }) => {
  const theme = useTheme();
  const PlayButton = styled(Button)({
    backgroundColor: "#E8EDF1",
    color: "#252A31",
    padding: "14px 24px",
    borderRadius: "6px",
    height: "52px",
    fontFamily: "Inter",
    fontWeight: "500",
    lineHeight: "24px",
    fontSize: "16px",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#E8EDF1",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2px 5px",
      height: "35px",
      lineHeight: "10px",
      fontSize: 10,
      gap: "5px",
    },
  });
  return (
    <PlayButton>
      <GrPlay /> {children}
    </PlayButton>
  );
};
