import React from "react";
import BasicCard from "../common/BasicCard";

export default function Courses() {
  const courses = [
    {
      id: 1,
      duration: "3 Month",
      price: "1500",
      courseName: "Computer Programming",
      isSpecial: false,
      tags: [
        { id: 1, title: "Learn C & Python" },
        { id: 2, title: "Exercises & Examples" },
        { id: 3, title: "Concepts and Tips" },
        { id: 4, title: "Build Projects" },
        { id: 5, title: "Hands-on Code" },
      ],
    },
    {
      id: 2,
      duration: "3 Month",
      price: "3000",
      courseName: "+2 Science Bridge Course",
      isSpecial: true,
      tags: [
        { id: 1, title: "Physics, Chemistry, Math, English Included" },
        { id: 2, title: "Online Curated Resources" },
        { id: 3, title: "Learn from your own home" },
        { id: 4, title: "Zoom Classes & Recordings" },
        { id: 5, title: "Model Questions" },
      ],
    },
    {
      id: 3,
      duration: "3 Month",
      price: "1500",
      courseName: "Graphics Design",
      isSpecial: false,
      tags: [
        { id: 1, title: "Concept of Graphics Design" },
        { id: 2, title: "Learn by Example" },
        { id: 3, title: "Daily Challenges and Critques" },
        { id: 4, title: "Zoom Classes & Recordings" },
        { id: 5, title: "Model Questions" },
      ],
    },
  ];
  return (
    <section id="courses"className="container py-10 lg:py-[100px] lg:my-3">
      <h1 className="font-manrope font-bold text-2xl lg:text-4xl text-center text-dark2 leading-[46px] ">
        Our Courses
      </h1>
      <div className="mt-16  grid lg:mt-24 lg:grid-cols-3 md:gap-4 2xl:gap-12">
        {courses.map((course) => (
          <BasicCard
            key={course.id}
            duration={course.duration}
            price={course.price}
            courseName={course.courseName}
            special={course.isSpecial}
            tags={course.tags}
          />
        ))}
      </div>
    </section>
  );
}
