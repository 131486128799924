import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from '../../assets/Logo.png'
const Navbar = () => {
    
    const Menus = [
      { title: "Home", id: "#home" },
      { title: "Courses", id: "#courses" },
      { title: "FAQs", id: "#faqs" },
      { title: "Contact Us", id: "#contact" },
    ];

    const handleClick = (id) =>{
      console.log(id)
      const section = document.querySelector(id);
      section.scrollIntoView({behavior: 'smooth', block: 'start'});

    }
  return (
    <div className=" sticky top-0 bg-white z-10 ">
      <nav className="flex flex-row items-center justify-between px-8 w-full py-1 gap-7 h-[60px] border-b border-solid border-[#D6DEE6]  backdrop-filter backdrop-opacity-0 z-10">
        <div className="flex flex-row items-center p-0 gap-12 w-[485px] h-[44px]">
        <a href="/">
          <div className="flex flex-row items-center gap-2 w-[103px] h-[32px]">
          
            <img src={Logo} alt="Logo" className="h-8 w-8 " />
            <span className="w-[63px] h-[24px] font-inter not-italic font-bold text-base leading-6 text-brand">Ed Tech</span>
            
          </div>
          </a>
        <ul className="flex flex-row justify-center gap-10 items-center w-[334px] h-[44px] ">
          {Menus.map((menu, index) => (
            <li key={menu.id} className="items-center flex h-[44px] bg-white rounded-md gap-2 cursor-pointer " onClick={() => handleClick(menu.id)}>
                <p className="h-5 font-inter not-italic font-medium text-sm leading-5 text-center text-[#252A31]">
                {menu.title}</p>
              
            </li>
          ))}
        </ul>
        </div>
        <div className="flex flex-row items-center p-0 gap-4 w-[357px] h-[44px]">
        <a
            
            href="/login"
          >
          <div className="flex flex-row gap-2 items-center py-3 w-48 h-11 font-inter font-medium text-sm leading-6 text-[#252A31] rounded-md"><p className="w-2 "></p><span>Already a user? Login</span><p className="w-4"></p></div>
          </a>
         
            <button className="bg-[#FFB100] flex flex-col items-center w-40 py-3 px-4 h-[44px] rounded-md"><span className="text-center w-[125px] h-[20px] font-inter not-italic font-medium text-sm leading-5 text-white">Create an Account</span></button>
          
        </div>
        </nav>
        </div>
   
  )
}
export default Navbar
