import { useTheme } from "@emotion/react";
import { Button, styled } from "@mui/material";
import React from "react";

export const PrimaryButton = ({ children, width }) => {
  const theme = useTheme();
  const PrimaryButton = styled(Button)({
    backgroundColor: theme.palette.brand.main,
    color: "#FFFFFF",
    padding: "14px 24px",
    borderRadius: "6px",
    width: width ? width : "auto",
    height: "52px",
    fontFamily: "Inter",
    fontWeight: "500",
    lineHeight: "24px",
    fontSize: "16px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.product.main,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2px 10px",
      height: "35px",
      lineHeight: "10px",
      fontSize: 10,
    },
  });
  return <PrimaryButton variant="contained">{children}</PrimaryButton>;
};
