import React from "react";
import Input from "../common/Input";
import { PrimaryButton } from "../buttons/PrimaryButton";

export default function Footer() {
  return (
    <>
      <section id="contact" className="bg-[#FEF7F1] py-[100px]">
        <footer className="container flex flex-col-reverse justify-center items-center gap-4 md:flex-row md:gap-3">
          <div className="flex flex-col text-center md:text-left justify-center">
            <h1 className="font-manrope font-bold text-3xl md:text-5xl leading-[46px] text-dark2 mb-6">
              Let's Talk
            </h1>
            <div className="space-y-5">
              <p className="text-black text-base md:text-[20px] font-inter font-normal leading-[27px]">
                If you any kind of queries feel free to drop your question with
                the form or you can directly call us. React out to our Social
                Handles
              </p>
              <div>
                <p className="font-manrope font-bold text-[20px] leading-[27px]">
                  Call us
                </p>
                <p className="font-Poppins font-normal text-[16px] leading-[24px] text-black">
                  +977 9845634523
                </p>
              </div>
              <div>
                <p className="font-manrope font-bold text-[20px] leading-[27px]">
                  Email Us
                </p>
                <a
                  href="edtech@gmail.com"
                  className="font-Poppins font-normal text-[16px] leading-[24px] text-black"
                  target={"_blank"}
                >
                  edtech@gmail.com
                </a>
              </div>
              <div className="flex flex-col gap-2">
                <p className="font-manrope font-bold text-[20px] leading-[27px]">
                  Social
                </p>
                <a
                  href="#"
                  className="font-Poppins font-normal text-[16px] leading-[24px] text-black underline"
                  target={"_blank"}
                >
                  Instagram
                </a>
                <a
                  href="#"
                  className="font-Poppins font-normal text-[16px] leading-[24px] text-black underline"
                  target={"_blank"}
                >
                  Twitter
                </a>
                <a
                  href="#"
                  className="font-Poppins font-normal text-[16px] leading-[24px] text-black underline"
                  target={"_blank"}
                >
                  Facebook
                </a>
              </div>
            </div>
          </div>
          <div className="w-[375px] bg-white rounded-xl shadow-md p-4">
            <h1 className="text-dark2 font-inter font-medium text-[20px] leading-[28px]">
              Contact Us
            </h1>
            <form className="my-2 space-y-4">
              <Input
                title="name"
                label="Name"
                placeholder="Enter your Full Name"
              />
              <Input
                title="email"
                type="email"
                label="Email"
                placeholder="example@gmail.com"
              />
              <Input
                type="textarea"
                placeholder={"your message"}
                label="Message"
              />
              <PrimaryButton width={"100%"}>Submit</PrimaryButton>
            </form>
          </div>
        </footer>
      </section>
      <div className="">
        <div className="container flex justify-between py-2 font-inter font-normal text-[#252A31] text-[14px]">
          <span>&copy; 2023 Copyright | All Right Reserved</span>
          <ul className="flex gap-1 flex-wrap md:gap-4 items-center">
            <li>@et</li>
            <li>Terms & Conditions</li>
            <li>FAQ</li>
          </ul>
        </div>
      </div>
    </>
  );
}
