import { createTheme } from "@mui/material/styles";
export const globalTheme = createTheme({
  palette: {
    brand: {
      main: "#FFB100",
    },
    primary: {
      main: "#68FE9A",
    },
    textSecondary: {
      main: "#747981",
    },
    backgroundColor: {
      main: "#FEFAF1",
    },
    product: {
      main: "#00A58E",
    },
    dark1: {
      main: "#060606",
    },
    dark2: {
      main: "#24272C",
    },
  },
  typography: {
    fontFamily: "Inter, Manrope, 'Playfair Display', Poppins",
  },
});
