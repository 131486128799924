import React from "react";
import HeroImage from "../../image/hero-section.png";
export default function HeroSection() {
  return (
    <section className="container text-center relative py-5 lg:py-32">
      <img
        src={HeroImage}
        alt="loading.."
        className=" w-screen lg:w-full h-full object-cover"
      />
      <div className="hero-section text-dark2 top-0 leading-[46px] font-manrope absolute lg:top-44 left-1/2 -translate-x-1/2">
        <h1 className="font-bold mb-1 text-base w-[350px] md:w-fit lg:w-[1042px] lg:text-4xl 2xl:text-5xl lg:mb-5">
          Make the most of your time after SEE with fun and productive
          activities.
        </h1>
        <p className="text-center font-medium  text-xs px-5 lg:px-0 lg:text-xl text-[#4F5E71] mb-6">
          Our courses are designed to provide you with the knowledge and skills
          necessary for your future.
        </p>
      </div>
    </section>
  );
}
