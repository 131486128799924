import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@emotion/react";

export default function BasicCard({
  special,
  duration,
  price,
  tags,
  courseName,
}) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        [theme.breakpoints.down("lg")]: {
          translate: "0px 0px 0px",
          scale: "0.7 0.8",
          marginTop: -10,
        },
        minWidth: 275,
        translate: special ? "0px -48px 0px" : 0,
        paddingX: "32px",
        boxShadow: "5px",
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow:
          "0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12)",
      }}
      variant="outlined"
    >
      <CardContent
        sx={{
          paddingTop: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: 20,
            fontFamily: "Poppins",
            fontWeight: 400,
            lineHeight: "30px",
            textAlign: "center",
          }}
          color="dark2.main"
          gutterBottom
        >
          {duration}
        </Typography>
        <Typography
          sx={{
            color: "product.main",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 40,
            lineHeight: "38px",
            textAlign: "center",
            marginTop: "8px",
          }}
          variant="h4"
          component="div"
        >
          Rs. {price}
        </Typography>
        <Typography
          sx={{
            color: "dark1.main",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 32,
            lineHeight: "38px",
            textAlign: "center",
            marginTop: "32px",
            marginBottom: "24px",
          }}
          color="text.secondary"
        >
          {courseName}
        </Typography>
        <Typography variant="div">
          <List
            sx={{
              fontFamily: "Inter",
              color: "textSecondary.main",
              fontSize: "14px",
              ".MuiListItem-root": {
                paddingY: 0,
              },
              ".MuiListItemIcon-root": {
                minWidth: "27px",
              },
            }}
          >
            {tags.map((tag) => (
              <ListItem key={tag.id}>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText primary={tag.title} />
              </ListItem>
            ))}
          </List>
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          textAlign: "center",
          paddingBlock: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "18px",
        }}
      >
        <PrimaryButton width={"100%"}>Enroll Now</PrimaryButton>
        <Button
          sx={{
            fontFamily: "Inter",
            color: "dark2.main",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          View Course
          <ChevronRightIcon
            sx={{
              mr: "15px",
            }}
          />
        </Button>
      </CardActions>
    </Card>
  );
}
