import React from "react";

export default function Quote() {
  return (
    <section className="my-5 px-12 py-[10px] text-center h-[237px]  bg-[#F5F7F9]  flex items-center justify-center flex-col gap-4 ">
      <h4 className=" font-semibold font-playfair-display text-dark2 text-xl md:text-2xl lg:text-4xl  2xl:text-5xl lg:w-[894px] lg:leading-[50px]">
        “Excellence without effort is as futile as progress without
        preparation.”
      </h4>
      <li className="text-[#4F5E71] font-semibold font-manrope text-center text-sm md:text-lg lg:text-xl">
        William Arthur Ward
      </li>
    </section>
  );
}
