import React from "react";
import Frame from "../../image/frame.png";
import { PlayButton } from "../buttons/PlayButton";
import { PrimaryButton } from "../buttons/PrimaryButton";

export default function Home() {
  return (
    <section id="home" className=" lg:container text-center  items-center">
      <img
        src={Frame}
        alt="frame"
        className="w-full h-full aspect-video object-cover"
      />
      <div className=" hero-section text-dark2 lg:leading-[55px] font-manrope absolute top-7 lg:top-40 left-1/2 -translate-x-1/2">
        <h1 className="font-[300] text-base w-[400px] lg:w-[1042px] md:w-fit md:text-2xl lg:text-5xl mb-1 lg:mb-2">
          Looking to upskill or reskill ?
          <span className="text-brand">EdTech</span> has you covered
        </h1>
        <p className="text-center text-xs px-5 mb-3 lg:px-0 font-semibold md:text-lg lg:text-xl md:mb-6">
          better learning experience, experience tutors and course personalized
          for you
        </p>
        <div className="flex justify-center gap-3">
          <PrimaryButton>View courses</PrimaryButton>
          <PlayButton>Watch Video</PlayButton>
        </div>
      </div>
    </section>
  );
}
