import React from "react";
import Courses from "../course/Courses";
import Footer from "../footer/Footer";
import HeroSection from "../heroSection/HeroSection";
import Home from "../home/Home";
import Quote from "../quote/Quote";
import Navbar from "../Navbar/Navbar"
import { Faq } from "./Home/Faq";

export default function Index() {
  return (
    <div className="">
 
      <Navbar />
     
      <Home />
      <Courses />
      <HeroSection />
      <Quote />
      <Faq />
      
      <Footer />
      
   
    </div>
  );
}
