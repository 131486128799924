import React from "react";

export default function Input({ type, title, label, placeholder }) {
  return (
    <div className="flex flex-col gap-1">
      <label
        htmlFor={title}
        className="font-inter text-base font-medium text-[#252A31]"
      >
        {label}
      </label>
      {type === "textarea" ? (
        <textarea
          className="border border-[#BAC7D5] placeholder-[#697D95] font-inter font-normal text-[14px] p-3 rounded-md focus:outline-blue-400"
          name={title}
          id={title}
          placeholder={placeholder || ""}
        />
      ) : (
        <input
          className="border border-[#BAC7D5] placeholder-[#697D95] font-inter font-normal text-[14px] p-3 rounded-md focus:outline-blue-400"
          placeholder={placeholder || ""}
          type={type || "text"}
          name={title}
          id={title}
        />
      )}
    </div>
  );
}
