import React from 'react'
import { useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Faq.css'
export const Faq = () => {
    const [activeIndex,setActiveIndex] = useState(-1)
    const faq = [
        {
            question: 'Question 1',
            answer: 'Non aliquip aliqua elit mollit sint culpa ullamco consequat reprehenderit laborum magna qui id occaecat.',
            
        },
        {
            question: 'Question 2',
            answer: 'Non aliquip aliqua elit mollit sint culpa ullamco consequat reprehenderit laborum magna qui id occaecat.',
        },
        {
            question: 'How can I take the online course?',
            answer: 'Firstly, you need to create an account, choose the course you want to take, buy the course by choosing the payment methods. After you have paid for the course, you will get upcoming classes link and information.',
        },
        {
            question: 'Question 4',
            answer: 'Non aliquip aliqua elit mollit sint culpa ullamco consequat reprehenderit laborum magna qui id occaecat.',
        },
        {
            question: 'Question 5',
            answer: 'Non aliquip aliqua elit mollit sint culpa ullamco consequat reprehenderit laborum magna qui id occaecat.',
        },
    ]
    const togglehandler = (index) => {
        setActiveIndex((prevState) => (prevState === index ? -1 : index));
    }
  return (
    <div id="faqs" className='flex flex-col justify-center items-center text-center px-72 py-8 gap-2 w-full h-[800px]'>
        <div className='flex flex-col items-start p-0 gap-4 w-full h-[518px]'>
        
            <h1 className="font-bold w-full  text-4xl leading-tight text-dark2" >Frequently Asked Questions</h1>
        
        <div className='flex flex-col justify-center w-full '>
            {faq.map((item,index) => (
                <div className={`${activeIndex === index ? 'bg-cloud_light rounded-md' : 'box-border bg-white border-b border-cloud_light'} flex flex-col  px-4 `}>
                <div className='flex flex-row justify-between items-center h-[72px]  '>
                <div>
                    <p className={`${activeIndex === index ? 'font-medium text-dark1': 'font-normal text-dark2  '} font-inter  text-base leading-6 text-start not-italic`}>{item.question}</p>
                </div>
                <div onClick={() => togglehandler(index)}>{activeIndex === index ? <KeyboardArrowDownIcon />:<ChevronRightIcon />} </div>
                    </div>
                   {activeIndex === index && (
                    <div className='h-[96px] py-3'>
                        <p className="font-inter text-start font-normal not-italic text-base leading-6 text-dark2">{item.answer}</p>
                    </div>
                   
                   )}
                    </div>
                    
            ))}
        </div>
        </div>
    </div>
  


  )
}
